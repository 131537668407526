export const colors = {
	black: '#000000',
	grey: '#424242',
	white: '#ffffff',
	gold: '#c5a880',
	copper: '#e1d4bb',
	tin: '#e6e6e6',
	aluminum: '#f7f7f7',
	dove: '#6f6f6f',
	mineShaft: '#2b2b2b',
	silverChalice: '#afafaf'
}
